.loading {
    margin-top: 10px;
    display:block;
    font-size: 16px;
    font-weight: 200;
    color: white;
    svg {
        color: white;
    }
    .text {
        display: inline-block;
        transform: translateY(-4px);
    }
    .spinner {
        display: inline-block;
    }
    font-family: Roboto,Helvetica,sans-serif,-apple-system;
}
