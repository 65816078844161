.container {
    min-width: 355px;
    position: relative;
    font-family: Roboto, Helvetica, sans-serif, -apple-system;

    .header {
        font-size: 26px;
        padding: 10px;
        text-align: center;
        line-height: 1.25em;
    }

    .overlay {
        background-image: url("/images/background.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        background-color: black;
        height: 1500px;
    }

    .ready-to-start {
        height: 20px;
        padding-bottom: 10px;
    }

    .contents {
        font-family: Roboto, Helvetica, sans-serif, -apple-system;
        font-size: 26px;
        background-color: black;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        opacity: 75%;
        height: -webkit-fill-available;
        height: 100%;
    }

    .body {
        position: absolute;
        top: 0;
        left: 0;
        color: white;
        width: 100%;
        .region {
            max-width: 400px;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .title {
        padding: 10px;
        .underline {
            text-decoration: underline;
        }
    }

    .sub-title {
        padding: 10px;
        color: #d9d9d9;
        text-align: center;
    }

    .roboto {
        font-family: Roboto, Helvetica, sans-serif, -apple-system;

        button {
            font-family: Roboto, Helvetica, sans-serif, -apple-system;
        }
    }

    .image {
        display: block;
        margin-left: auto;
        margin-right: auto;
        max-width: 1000px;
    }

    .centered {
        width: fit-content;
        margin: auto;
    }

    .button {
        background-color: #ffc000;
        color: black;
        border-radius: 100px;
        width: 200px;
        vertical-align: middle;
        height: 40px;
    }

    .list {
        color: #d9d9d9;
        padding-inline-start: 5px;
        li {
            height: 1.8rem;

            &::marker {
                color: transparent;
            }
        }
    }

    .list {
        li:before {
            content: url("/images/change_history.png");
            padding-right: 10px;
            padding-left: -10px;
        }
    }

    .ready-to-start {
        color: white;
    }

    .wait-indicator {
        padding-top: 50px;
        padding-bottom: 50px;
        margin: auto;
        width: fit-content;
        color: #FFC000;
    }

    .instrumented-icon-button {
        color: white;
        padding: 0px;
        margin: 0px;
        background-color: transparent;
        border: none;
        cursor: pointer;
    }

    .letter-spacing {
        letter-spacing: 0.5px;
    }
}
